import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import XLSX from 'xlsx';
import {useOutlet} from 'reconnect.js';

export default function ProductXlsxDownloadModal(props) {
  const {visible, setVisible} = props;

  return (
    <Ant.Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={500}
      closable={true}
      maskClosable={true}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [locale] = useOutlet('locale');
  const [downloadLink, setDownloadLink] = React.useState(null);

  const productRowLabels = locale?.lang
    ? [
        '商品型號',
        '名稱',
        '價錢',
        '商品等級',
        '性別',
        '分類標籤',
        '尺寸',
        '寬度',
        '版型',
        '行銷款',
        'assortment',
        '正掛',
        '產品說明',
        '備註',
      ]
    : [
        'SKU',
        'Style Code',
        'Retail Price',
        'Product Tier',
        'Gender',
        'Category',
        'Size',
        'Width',
        'Fit',
        'Key Look',
        'Assortment',
        '正掛',
        'Product Info',
        'Remark',
      ];

  function productToRow(product) {
    return [
      product.model,
      product.name,
      product.price,
      (product.levels || [])
        .reduce((acc, l) => {
          return acc + l + ',';
        }, '')
        .slice(0, -1),
      product.gender,
      (product.labels || []).reduce((acc, label) => `${acc}\n ${label}`, ''),
      product.size,
      product.width,
      product.pattern,
      product.marketing,
      product.assortment,
      product['正掛'],
      product.description,
      product.note,
    ];
  }

  async function createDownloadLink() {
    AppActions.setLoading(true);
    try {
      const products = await JStorage.fetchAllDocuments('product', {}, [
        'model',
      ]);

      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.aoa_to_sheet([
        productRowLabels,
        ...products.filter((product) => product.type === 1).map(productToRow),
      ]);

      const ws2 = XLSX.utils.aoa_to_sheet([
        productRowLabels,
        ...products.filter((product) => product.type === 2).map(productToRow),
      ]);

      const ws3 = XLSX.utils.aoa_to_sheet([
        productRowLabels,
        ...products.filter((product) => product.type === 3).map(productToRow),
      ]);

      XLSX.utils.book_append_sheet(
        wb,
        ws1,
        locale?.lang === 'tw' ? '鞋' : 'Shoe',
      );
      XLSX.utils.book_append_sheet(
        wb,
        ws2,
        locale?.lang === 'tw' ? '衣' : 'Clothe',
      );
      XLSX.utils.book_append_sheet(
        wb,
        ws3,
        locale?.lang === 'tw' ? '配' : 'Acc',
      );

      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array',
        cellStyles: true,
        bookImages: true,
      });

      const objUrl = URL.createObjectURL(
        new Blob([wbout], {type: 'application/octet-stream'}),
      );
      setDownloadLink(objUrl);
      Ant.message.success('success');
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <h2>{locale?.lang === 'tw' ? '下載產品列表' : 'Download'}</h2>
      <div className="hint">
        {locale?.lang === 'tw'
          ? '注意: 此下載功能l與上傳excel的格式不同, 僅供用於檢查商品資料, 請勿將此檔案直接用於上傳功能!'
          : 'Note: The export excel format is for data inspection only. Do not upload export data'}
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Ant.Button onClick={createDownloadLink}>
          {locale?.lang === 'tw' ? '產生下載連結' : 'Create Link'}
        </Ant.Button>
        {downloadLink && (
          <Ant.Button
            href={downloadLink}
            target="_blank"
            download={'product-list.xlsx'}
            type="link">
            {locale?.lang === 'tw' ? '點此下載' : 'Click to Download'}
          </Ant.Button>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 20px;

  & > .hint {
    padding: 20px;
    border-radius: 16px;
    background-color: #eee;
    margin: 20px 0;
  }
`;
