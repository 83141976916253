import React from 'react';
import XLSX from 'xlsx';
import uploadXlsx from '../../../cli/commands/upload';
import {CloudUploadFill} from '@styled-icons/bootstrap/CloudUploadFill';
import {L} from '../../AppActions';

function UploadXlsx() {
  const [workbook, setWorkbook] = React.useState(null);

  React.useLayoutEffect(() => {
    let input_dom_element = document.getElementById('input_dom_element');
    input_dom_element.addEventListener('change', handleFile, false);

    function handleFile(e) {
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
        setWorkbook(XLSX.readFile(e.target.result));
        /* DO SOMETHING WITH workbook HERE */
      };
      reader.readAsArrayBuffer(file);
    }
  }, []);

  return (
    <span style={{display: 'flex', alignItems: 'center', margin: '20px 0px'}}>
      <p
        style={{
          padding: 5,
          borderRadius: 4,
          color: '#168037',
          fontWeight: 'bold',
          marginRight: 10,
        }}>
        {`${L('upload_xlsx_file')} →`}
      </p>
      <input type="file" id="input_dom_element" />
      <button
        style={{
          backgroundColor: '#33a852',
          color: '#fff',
          border: 0,
          borderRadius: 5,
          padding: 10,
          letterSpacing: 1,
          cursor: 'pointer',
        }}
        onClick={() => uploadXlsx(workbook, 'nb-admin:86805358')}>
        <CloudUploadFill size={20} style={{marginRight: 5}} />
        {L('upload_xlsx_to_database')}
      </button>
    </span>
  );
}

export default UploadXlsx;
